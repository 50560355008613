import { Card } from "@coherence-design-system/controls";
import { CheckboxVisibility, DefaultButton, IColumn, mergeStyles,
    MessageBar,
    MessageBarType,
    PrimaryButton, ScrollablePane, Selection, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, Toggle } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import { IOffboardingRequest, IResetPendingOffboardRequest } from "../../Pages/OffboardingPage";
import HttpService from "../../services/HttpService/HttpService";
import { getHeader } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { UnauthorizedMessage } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";

interface IStaleServersProps {}

interface IStaleServers {
    ServerId: number;
    Service: string;
    DaysRemaining: number;
    Remarks: string; 
}

const cardSize = mergeStyles({
    width: "70vw",
    height: "40vh",
});

const offboardMessageSuccess = "Successfully Offboarded Server(s)!";
const offboardMessageError = "Could not offboard servers. Please try again.";

export const StaleServers: React.FunctionComponent<IStaleServersProps> = (props: IStaleServersProps) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [dataGridData, SetDataGridData] = React.useState<IStaleServers[]>([]);
    const [offboardMessage, SetOffboardMessage] = React.useState<string>("");
    const [resetPendingOffboardMessage, SetResetPendingOffboardMessage] = React.useState<string>("");
    const [currentlyResetting, SetCurrentlyResetting] = React.useState<boolean>(false);
    const [serversLoaded, SetServersLoaded] = React.useState<boolean>(false);
    const [currentlyOffboarding, SetCurrentlyOffboarding] = React.useState<boolean>(false);
    const [selectionModeServers, SetSelectionModeServers] = React.useState<boolean>(false);
    const [isOffboardButtonDisabled, { setTrue: hideOffboardButton , setFalse: showOffboardButton}] = useBoolean(true);
    const [isResetButtonDisabled, { setTrue: hideResetButton , setFalse: showResetButton}] = useBoolean(true);
    const [selection] = React.useState(new Selection({
        onSelectionChanged: () => {
            if(selection.getSelectedIndices().length > 0) { showOffboardButton(); showResetButton() } else {hideOffboardButton(); hideResetButton();}
        },
    }));

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        loadServers();
    }, []);

    const loadServers = () => {
        SetServersLoaded(false);

        const getStaleServers = httpService.get({
            url: "api/ServerProcessing/GetServersPendingOffboard",
            token: state.AuthStore.Token,
            params: {},
        });

        const staleServers: IStaleServers[] = [];

        getStaleServers.then((response: any) => {
            response?.data.forEach((server: any) => {
                staleServers.push({
                    ServerId: server.ServerId as number,
                    Service: server.ServiceTreeGUID.toString(),
                    DaysRemaining:server.DaysRemaining as number,
                    Remarks: server.Remarks,
                });
            });

            SetDataGridData(staleServers);
            SetServersLoaded(true);
        }).catch((error: any) => {
            if (error.response.status === 401) {
                staleServers.push({
                    ServerId: 0,
                    Service: UnauthorizedMessage,
                    DaysRemaining:10,
                    Remarks: "",
                });
            } else {
                staleServers.push({
                    ServerId: 0,
                    Service: "Something went wrong",
                    DaysRemaining:10,
                    Remarks: "",
                });
            }

            SetDataGridData(staleServers);
            SetServersLoaded(true);
            trackException(appInsights, error, SeverityLevel.Error, "Admin", "Get Servers Pending Offboarding", "Get Servers Pending Offboarding", state.AuthStore, {});
        });
    }

    const resultsColumns: IColumn[] = [
        {
            key: "ServerId",
            name: "Server ID",
            fieldName: "ServerId",
            minWidth: 70,
            maxWidth: 135,
            isResizable: true,
        },
        {
            key: "Service",
            name: "Service",
            fieldName: "Service",
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
        },
        {
            key: "DaysRemaining",
            name: "Days Remaining",
            fieldName: "DaysRemaining",
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
        }, 
        {
            key: "Remarks",
            name: "Remarks",
            fieldName: "Remarks",
            minWidth: 70,
            maxWidth: 135,
            isResizable: true,
        },
    ];

    const onRenderItemColumn = (item?: any, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IStaleServers] as any;

            switch (column.fieldName) {
                case "DateOffboarded": {
                    return <span>{(fieldContent as Date).toDateString()}</span>;
                }
                default:
                    return <span>{fieldContent}</span>;
            }
        }
    };

    const offboardStaleServers = () => {
        SetOffboardMessage("");
        SetCurrentlyOffboarding(true);
        const adminPageOffboardText: string = "Offboarded by admin page.";
        const offboardingRequestMap: Map<string, IOffboardingRequest> = new Map();
        const offboardingRequestArr: IOffboardingRequest[] = [];

        for (let i = 0; i < selection.getSelectedCount(); i++) {
            const service: string = (selection.getSelection()[i] as IStaleServers).Service;
            const serverId: number = (selection.getSelection()[i] as IStaleServers).ServerId;

            if (offboardingRequestMap.has(service)) {
                const offboardingRequest: IOffboardingRequest = offboardingRequestMap.get(service)!;
                
                if (offboardingRequest !== null) {
                    const serverIDs = offboardingRequest?.ServerIDs;
                    serverIDs.push(serverId);

                    offboardingRequestMap.set(service, {
                        ServiceTreeGUID: service,
                        ServerIDs: serverIDs,
                        OffboardingRemark: adminPageOffboardText,
                    });
                }
            } else {
                const defaultOffboardingRequest: IOffboardingRequest = {
                    ServerIDs: [serverId],
                    OffboardingRemark: adminPageOffboardText,
                    ServiceTreeGUID: service,
                }

                offboardingRequestMap.set(service, defaultOffboardingRequest);
            }
        }

        for (const key of offboardingRequestMap.keys()) {
            offboardingRequestArr.push(offboardingRequestMap.get(key)!);
        }

        performOffboarding(offboardingRequestArr);
    };

    const performOffboarding = (request: IOffboardingRequest[]) => {
        httpService.post({
            url: "api/ServerProcessing/OffboardStaleServers",
            token: state.AuthStore.Token,
            data: request,
          }).then((resp: any) => {
            SetCurrentlyOffboarding(false);
            SetOffboardMessage(offboardMessageSuccess);
            loadServers();
          }).catch((error: any) => {
            SetOffboardMessage(offboardMessageError);
            trackException(appInsights, error, SeverityLevel.Error, "Admin", "Offboard Stale Servers", "Offboard Stale Servers", state.AuthStore, {});
          });
    }

    const resetServersPendingOffboard = () => {
        SetResetPendingOffboardMessage("");
        SetCurrentlyResetting(true);

        const resetRequest: IResetPendingOffboardRequest = { serverids: []};

        for (let i = 0; i < selection.getSelectedCount(); i++) {
            const service: string = (selection.getSelection()[i] as IStaleServers).Service;
            const serverId: number = (selection.getSelection()[i] as IStaleServers).ServerId;

            resetRequest.serverids.push(serverId);

        }

        resetServersPendingOffboardAPICall(resetRequest);
    };


    const resetServersPendingOffboardAPICall = (request: IResetPendingOffboardRequest) => {
        httpService.post({
            url: "api/ServerProcessing/resetServersPendingOffboard",
            token: state.AuthStore.Token,
            data: request,
          }).then((resp: any) => {
            let count :Number = resp?.data?.["Result"]?.length;
            count = count || 0;
            SetCurrentlyResetting(false);
            SetResetPendingOffboardMessage(`PendingOffboard was reset successfully for (${count}) server(s)`);
            loadServers();
          }).catch((error: any) => {
            SetResetPendingOffboardMessage("Could not Reset PendingOffboard! Please try again.");
            trackException(appInsights, error, SeverityLevel.Error, "Admin", "Reset PendingOffboard", "Reset PendingOffboard", state.AuthStore, {});
          });
    }

    return (
        <div className={cardSize}>
            <Card header={getHeader("Offboard Stale Servers", "left")}>
                { resetPendingOffboardMessage.length > 0 && 
                    <MessageBar
                    messageBarType={resetPendingOffboardMessage.indexOf("success") > 0 ?MessageBarType.success : MessageBarType.warning}
                    isMultiline={false}
                    onDismiss={() => {SetResetPendingOffboardMessage("")}}
                    >
                    {resetPendingOffboardMessage}
                    </MessageBar>
                }
                <PrimaryButton
                    disabled={isOffboardButtonDisabled}
                    onClick={offboardStaleServers}
                >
                    Offboard
                </PrimaryButton>

                <DefaultButton
                    className={mergeStyles({marginLeft: "20px"})}
                    disabled={isResetButtonDisabled}
                    onClick={resetServersPendingOffboard}
                >
                    Reset Pending Offboard
                </DefaultButton>
                <div hidden={!currentlyOffboarding}>
                    <Spinner label="Offboarding Stale Servers" size={SpinnerSize.medium} ariaLive="assertive" />
                </div>
                <div>
                    <span>{offboardMessage}</span>
                    <span>Multi-Select?</span>
                    <Toggle 
                        defaultChecked={selectionModeServers}
                        inlineLabel
                        onText="On"
                        offText="Off"
                        onChange={() => SetSelectionModeServers(!selectionModeServers)}
                    />
                </div>
                <ScrollablePane style={{ height: "25vh", position: "relative" }}>
                    <ShimmeredDetailsList
                        columns={resultsColumns}
                        items={dataGridData}
                        compact={true}
                        onRenderDetailsHeader={RenderStickyHeader}
                        checkboxVisibility={CheckboxVisibility.onHover}
                        onRenderItemColumn={onRenderItemColumn}
                        selectionMode={selectionModeServers ? SelectionMode.multiple : SelectionMode.none }
                        selection={selection}
                        enableShimmer={!serversLoaded}
                    />
                </ScrollablePane>
            </Card>
        </div>
    );
};
