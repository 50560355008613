import { Card } from "@coherence-design-system/controls";
import { CheckboxVisibility, IColumn, mergeStyles,
    PrimaryButton, ScrollablePane, Selection, SelectionMode, 
    ShimmeredDetailsList, Spinner, SpinnerSize, Toggle } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { RenderStickyHeader } from "../../Helpers/StickyHeader";
import HttpService from "../../services/HttpService/HttpService";
import { getHeader } from "../../Styles/Page.styles";
import { useAppInsights } from "../AppInsights/AppInsights";
import { trackException } from "../AppInsights/LoggingHelper";
import { UnauthorizedMessage } from "../Shared/AppConstants";
import { RootContext } from "../Stores/RootStore";

interface IStaleServicesProps {}

interface IStaleServices {
    ServiceTreeGUID: string;
    ServiceName: string;
}

interface IOffboardService {
    ServiceTreeGUID: string;
}

const cardSize = mergeStyles({
    width: "70vw",
    height: "40vh",
});

const offboardMessageSuccess = "Successfully Offboarded Service(s)!";
const offboardMessageError = "Could not offboard services. Please try again.";

export const StaleServices: React.FunctionComponent<IStaleServicesProps> = (props: IStaleServicesProps) => {
    const { state } = React.useContext(RootContext); // auth token context
    const [dataGridData, SetDataGridData] = React.useState<IStaleServices[]>([]);
    const [offboardMessage, SetOffboardMessage] = React.useState<string>("");
    const [servicesLoaded, SetServicesLoaded] = React.useState<boolean>(false);
    const [currentlyOffboarding, SetCurrentlyOffboarding] = React.useState<boolean>(false);
    const [selectionModeServices, SetSelectionModeServices] = React.useState<boolean>(false);
    const [isOffboardButtonDisabled, { setTrue: hideOffboardButton , setFalse: showOffboardButton}] = useBoolean(true);
    const [selection] = React.useState(new Selection({
        onSelectionChanged: () => {
            selection.getSelectedIndices().length > 0 ? showOffboardButton() : hideOffboardButton();
        },
    }));

    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state));

    React.useEffect(() => {
        loadServices();
    }, []);

    const loadServices = () => {
        SetServicesLoaded(false);

        const getStaleServices = httpService.get({
            url: "api/ServiceAdministration/fetchStaleServices",
            token: state.AuthStore.Token,
            params: {},
        });

        getStaleServices.then((response: any) => {
            const staleServices: IStaleServices[] = [];
            
            if (response?.data !== UnauthorizedMessage) {
                response?.data.forEach((service: any) => {
                    staleServices.push({
                        ServiceTreeGUID: service.serviceTreeGUID,
                        ServiceName: service.serviceName,
                    });
                });

                SetDataGridData(staleServices);
                SetServicesLoaded(true);
            } else {
                SetServicesLoaded(true);
                staleServices.push({
                    ServiceTreeGUID: "",
                    ServiceName: UnauthorizedMessage,
                });

                SetDataGridData(staleServices);
            }
        }).catch((error: any) => {
            trackException(appInsights, error, SeverityLevel.Error, "Admin", "Get Services Pending Offboarding", "Get Services Pending Offboarding", state.AuthStore, {});
        });
    }

    const resultsColumns: IColumn[] = [
        {
            key: "ServiceName",
            name: "Service",
            fieldName: "ServiceName",
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
        },
        {
            key: "ServiceTreeGUID",
            name: "ServiceTreeGUID",
            fieldName: "ServiceTreeGUID",
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
        },
    ];

    const onRenderItemColumn = (item?: any, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {
        if (item !== undefined && column !== undefined) {
            const fieldContent = item[column.fieldName as keyof IStaleServices] as any;

            switch (column.fieldName) {
                default:
                    return <span>{fieldContent}</span>;
            }
        }
    };

    const offboardStaleService = () => {
        SetOffboardMessage("");
        SetCurrentlyOffboarding(true);

        const offboardingRequestArr: IOffboardService[] = [];

        for (let i = 0; i < selection.getSelectedCount(); i++) {
            const serviceTreeGUID: string = (selection.getSelection()[i] as IStaleServices).ServiceTreeGUID;

            offboardingRequestArr.push({ServiceTreeGUID: serviceTreeGUID});
        }

        performOffboarding(offboardingRequestArr);
    };

    const performOffboarding = (request: IOffboardService[]) => {
        httpService.delete({
            url: "api/ServiceAdministration/OffboardServices",
            token: state.AuthStore.Token,
            params: request,
          }).then((resp: any) => {
            SetCurrentlyOffboarding(false);
            SetOffboardMessage(offboardMessageSuccess);
            loadServices();
          }).catch((error: any) => {
            SetOffboardMessage(offboardMessageError);
            trackException(appInsights, error, SeverityLevel.Error, "Admin", "Offboard Stale Services", "Offboard Stale Services", state.AuthStore, {});
          });
    }

    return (
        <div className={cardSize}>
            <Card header={getHeader("Offboard Stale Services", "left")}>
                <PrimaryButton
                    disabled={isOffboardButtonDisabled}
                    onClick={offboardStaleService}
                >
                    Offboard
                </PrimaryButton>
                <div hidden={!currentlyOffboarding}>
                    <Spinner label="Offboarding Stale Services" size={SpinnerSize.medium} ariaLive="assertive" />
                </div>
                <div>
                    <span>{offboardMessage}</span>
                </div>
                <span>Multi-Select?</span>
                    <Toggle 
                        defaultChecked={selectionModeServices}
                        inlineLabel
                        onText="On"
                        offText="Off"
                        onChange={() => SetSelectionModeServices(!selectionModeServices)}
                    />
                <ScrollablePane style={{ height: "25vh", position: "relative" }}>
                    <ShimmeredDetailsList
                        columns={resultsColumns}
                        items={dataGridData}
                        compact={true}
                        onRenderDetailsHeader={RenderStickyHeader}
                        checkboxVisibility={CheckboxVisibility.onHover}
                        onRenderItemColumn={onRenderItemColumn}
                        selectionMode={selectionModeServices ? SelectionMode.multiple : SelectionMode.none}
                        selection={selection}
                        enableShimmer={!servicesLoaded}
                    />
                </ScrollablePane>
            </Card>
        </div>
    );
};
