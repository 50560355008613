import { Depths, IDropdownOption, ScrollablePane,
    Spinner, SpinnerSize } from "@fluentui/react";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { toNumber } from "lodash";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router";
import { IServerType, IServiceDataType, PAGE } from "../App";
import { useAppInsights } from "../components/AppInsights/AppInsights";
import { buildPageViewTelemetry, trackException, trackPageView } from "../components/AppInsights/LoggingHelper";
import { TimeZone } from "../components/Layout/Header";
import { DetailsListManageJobs } from "../components/ManageJobs/DetailsListManageJobs";
import { PatchComplianceType } from "../components/ManageServers/DetailsListManageServers";
import { BackToTopButton } from "../components/Shared/BackToTopButton";
import { UnauthorizedMessage } from "../components/Shared/AppConstants";
import { PagePermissionsComponent } from "../components/Shared/PagePermissionsComponent";
import { RootContext } from "../components/Stores/RootStore";
import { UserPermission } from "../services/AuthenticationService/AuthReducer";
import HttpService from "../services/HttpService/HttpService";
import { breakWord, _Styles } from "../Styles/Page.styles";
import { IPageProps } from "../Styles/Page.types";
import { DataActionEnum } from "../services/DataContext/DataActions";
import { AdminMessageBanner } from "../components/Home/AdminMessageBanner";

export const weekDays: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const weekDaysOptions: IDropdownOption[] = [];

weekDays.map((day: string) => weekDaysOptions.push({key: day, text: day}));

export const invalidCharArray: string[] = ["<", ">", "*", "%", "&", ":", "\\", "?", ".", "+", "#", "/", " ", "@", "!", "(", ")", "!", "~", "`", "$", "^", "[","]","\"","|"];
export const invalidCharSet: Set<string> = new Set<string>();

for (const c of invalidCharArray) {
    invalidCharSet.add(c);
}

export interface IManageJobsProps {
    panelOptions: IPanelOptions;
    refreshData: number;
    timezone: TimeZone | undefined;
    selectedService: IServiceDataType | undefined;
    appInsights: ApplicationInsights | undefined;
}

export interface IPanelOptions {
    daysOptions: IDropdownOption[];
    durationOptions: IDropdownOption[];
    serverOptions: IServerType[];
    weekOfMonthOptions: IDropdownOption[];
}

const pageTitle: string = "Manage Jobs";

export const ManageJobsPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
    const { state, dispatch } = React.useContext(RootContext);
    const [refreshData, SetRefreshData] = React.useState<number>(0);

    // Dropdown Data
    const [serversLoaded, SetServersLoaded] = React.useState<boolean>(false);
    const [durationLoaded, SetDurationLoaded] = React.useState<boolean>(false);
    const [weeksLoaded, SetWeeksLoaded] = React.useState<boolean>(false);

    const [defaultDaysOptions] = React.useState<IDropdownOption[]>(weekDaysOptions);
    const [defaultDurationOptions, SetDurationOptions] = React.useState<IDropdownOption[]>([]);
    const [defaultServerOptions, SetServerOptions] = React.useState<IServerType[]>([]);
    const [defaultWeekOfMonthOptions, SetWeekOfMonthOptions] = React.useState<IDropdownOption[]>([]);
    
    // read "serviceTreeGuid" from url param using useParams() hook
    const { serviceTreeGuid: serviceTreeGuidFromParam } = useParams();
    const [selectedService] = React.useState<IServiceDataType>({ServiceName: "", ServiceTreeGUID: serviceTreeGuidFromParam && serviceTreeGuidFromParam.length > 0 ? serviceTreeGuidFromParam : ""});
    
    const appInsights = useAppInsights();

    // HttpService
    const [httpService] = React.useState(HttpService(appInsights, state)); 

    React.useEffect(() => {
        document.title = pageTitle;

        dispatch({
            type: DataActionEnum.UPDATE_CURRENT_PAGE,
            currentPage: "managejobs"
        });

        const telemetry = buildPageViewTelemetry(
            pageTitle,
            "/managejobs",
            "/managejobs",
            state.AuthStore.Email,
            state.AuthStore.isAdmin,
        );
        
        trackPageView(appInsights, telemetry);
    }, []);

    React.useEffect(() => {
        if (state.AuthStore.Token !== "") {
            const calls: Promise<any>[] = [];

            const fetchServerData = httpService.get({
                url: "api/ManageServers/fetchServerData",
                token: state.AuthStore.Token,
                params: { 
                    IncludeVulnData: false,
                    timezone: state.AuthStore.timezone?.standardName,
                 },
            }).then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined && data !== UnauthorizedMessage) {
                    let serverOptions: IServerType[] = [];
                    for (const d of data) {
                        if (d.ServerName !== "") {
                            serverOptions = [...serverOptions, {
                                ServerId: toNumber(d.ServerId),
                                ServerName: d.Name,
                                ServiceName: d.ServiceName,
                                ServiceTreeGUID: d.ServiceTreeGUID,
                                AgentLastSeenDateTime: d.AgentLastSeenDateTime, 
                                LastScheduledDateTime: d.LastScheduledDateTime,
                                NextScheduledDateTime: d.NextScheduledDateTime!,
                                PatchCompliance: PatchComplianceType.Compliant,
                                VulnerabilityCount: 0,
                                OSType: d.OSType,
                            }];
                        }
                    }

                    SetServerOptions(serverOptions);
                    SetServersLoaded(true);
                }
            }).catch((reason: any) => {
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Jobs", "fetchAndLoadData", "api/PatchingJob/fetchServerData", state.AuthStore, {});
            });

            calls.push(fetchServerData);

            const fetchDuration = httpService.get({
                url: "api/duration",
                token: state.AuthStore.Token,
                params: {},
            }).then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined && data !== UnauthorizedMessage) {
                    let options: IDropdownOption[] = [];

                    for (const d of data) {
                        options = [ ...options, { key: d.duration, text: d.duration }];
                    }

                    SetDurationOptions(options);
                    SetDurationLoaded(true);
                }
            }).catch((reason: any) => {
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Jobs", "fetchAndLoadData", "api/duration", state.AuthStore, {});
            });

            calls.push(fetchDuration);

            const fetchWeekOfMonth = httpService.get({
                url: "api/weekofmonth",
                token: state.AuthStore.Token,
                params: {},
            }).then((response: any) => {
                const data: any = response?.data;
                if (data !== "" && data !== undefined && data !== UnauthorizedMessage) {
                    let options: IDropdownOption[] = [];

                    for (const d of data) {
                        options = [ ...options, { key: d.weekNumber, text: d.weekNumber }];
                    }

                    SetWeekOfMonthOptions(options);
                    SetWeeksLoaded(true);
                }
            }).catch((reason: any) =>{
                trackException(appInsights, reason, SeverityLevel.Error, "Manage Jobs", "fetchAndLoadData", "api/weekofmonth", state.AuthStore, {});
            });

            calls.push(fetchWeekOfMonth);

            const fetchAllData = async () => {
                await Promise.all(calls);
            };

            fetchAllData();
        }
    }, [state.AuthStore.Token]);

    const manageJobPanelOptions: IPanelOptions = {
        daysOptions: defaultDaysOptions,
        durationOptions: defaultDurationOptions,
        weekOfMonthOptions: defaultWeekOfMonthOptions,
        serverOptions: defaultServerOptions,
    };

    const manageJobsProps: IManageJobsProps = {
        panelOptions: manageJobPanelOptions,
        refreshData,
        timezone: state.AuthStore.timezone,
        selectedService: selectedService,
        appInsights: appInsights
    };

    return (
        <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
            <div style={{ boxShadow: Depths.depth8, position: "relative", display: "block", margin: "0px"}}>
                <AdminMessageBanner page={PAGE.MANAGEJOBS} />
            </div>
            <div className={_Styles.rootDiv}>
                <div hidden={state.AuthStore.UserPermitted !== UserPermission.Permitted || state.AuthStore.UserTimeOut}>
                    <h1 id="managejobstitle" className={breakWord}>
                        {ReactHtmlParser(pageTitle)}
                    </h1>
                    {/* Load DetailsList after data is loaded */}
                    {serversLoaded && durationLoaded && weeksLoaded && state.AuthStore.Token !== "" ? (
                        <DetailsListManageJobs {...manageJobsProps} />
                    ) : (
                        <div
                            style={{
                                position: "absolute", left: "50%", top: "40%",
                                transform: "translate(-50%, -50%)",
                            }}>
                            <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                        </div>
                    )}
                </div>
                <BackToTopButton />
                <PagePermissionsComponent />
            </div>
        </ScrollablePane>
    );
};
